export const getAccessToken = (testing) => {
    if (testing) return "eyJraWQiOiI1bXhYSTN1bEhjN2VTT3VDMS15TEM1ai1INVU1YmtJTVhNMm9SR05SeU1zIiwiYWxnIjoiUlMyNTYifQ.eyJzdWIiOiIwMHUxMHZndW5vbXZ4cVEzaDJwOCIsIm5hbWUiOiJOYWRpYSBIb3NzYWluIiwiZW1haWwiOiJOYWRpYS5Ib3NzYWluQHNyc2Rpc3RyaWJ1dGlvbi5jb20iLCJ2ZXIiOjEsImlzcyI6Imh0dHBzOi8vc3JzLm9rdGEuY29tL29hdXRoMi9hdXN2b2pndjl4dnpDU0RpWDJwNyIsImF1ZCI6IjBvYXY2eWFkMDdSa3NlNzVaMnA3IiwiaWF0IjoxNzQzNjA2NTY5LCJleHAiOjE3NDM2MTAxNjksImp0aSI6IklELmpYZENkNkxITFZQRlpueHdRUk1HX3lDRkx2dDNDVTNXcWUwOVJHMnE4YzAiLCJhbXIiOlsibWZhIiwib3RwIiwicHdkIl0sImlkcCI6IjAwbzE2emp3ZWNDRnU3cFVqMnA3Iiwibm9uY2UiOiJjeHhnSnF2eGs1b01rZUtCM2N0SWR1RU5kZjBNdGt3M1V5eXJ0N0tYVlR3a0ZrNXJ2TVM3eFFKbndzZ0dsNUdVIiwicHJlZmVycmVkX3VzZXJuYW1lIjoiTkgxMTAwMzZAbW1oZmdiLmNvbSIsImF1dGhfdGltZSI6MTc0MzYwNjU2NiwiYXRfaGFzaCI6ImJ4c1RlM0kyUWdYOFZ2UG5heFpMbncifQ.7CoX6gSiUt5HHM2dNlmsqLf1mZXQ2G4XwJS_jdBGuTN7Q6Z_7jAESc6uVROtbukXFYnhVprelXBg0IG1a0BM4w2aXXdFpp1UyuBkIQC3DE4xGAW0eLiaUIXPpw6cUFSkvjLv3VJ_5ZHn-hTJx4AwuVO7IJw0fRRjlTO7EzaaeH0xp3SYeZdA47A8u6ClRCD9Q5LKE4CBZoTgrEHh2V7wbwTFgxl7IVJflYhYKyOZwXxqStM8spy1Lh4yi_HlWBq7pVrR_tHEF8ydKm2Yxqsq0dI6ieRSMqqORYyvynd-jLk_T0kXv-yLPKnY2x2QCtoqqwoLjqejeurJ-KIN2FP-7Q"
    const oktaTokenStorage = JSON.parse(localStorage.getItem('okta-token-storage'));
    return oktaTokenStorage?.idToken?.idToken;
};

export const getLoggedInUser = async (testing) => {
  if (testing) return { userId: 'CC106472', firstName: 'Test', lastName: 'User' };

  const retryDelay = 500;
  const maxRetries = 5;

  // Helper function to wait for a specified duration
  const delay = (ms) => new Promise((resolve) => setTimeout(resolve, ms));

  // Function to attempt fetching user data with retries
  const fetchFromOktaTokenStorage = async (retries) => {
    const oktaTokenStorage = JSON.parse(localStorage.getItem('okta-token-storage'));

    // Ensure tokens are ready
    if (!oktaTokenStorage || !oktaTokenStorage.idToken) {
      if (retries > 0) {
        await delay(retryDelay); // Wait before retrying
        return fetchFromOktaTokenStorage(retries - 1); // Retry fetching
      }
      return null; // Return null if all retries are exhausted
    }

    const preferredUsername = oktaTokenStorage.idToken.claims?.preferred_username;
    const name = oktaTokenStorage.idToken.claims?.name;

    if (preferredUsername && name) {
      const [firstPart] = preferredUsername.split('@');
      const [firstName, lastName] = name.split(' ');
      return { userId: firstPart, firstName: firstName || '', lastName: lastName || '' };
    }

    return null;
  };

  return await fetchFromOktaTokenStorage(maxRetries);
};
