"use client";

import { useState, useEffect, useContext } from "react";
import { ContractorModal } from "./components/contractor-modal";
import { ContractorCard } from "./components/contractor-card";
import { LeadSourceCarousel } from "./components/lead-source-carousel";
import { AddLeadModal } from "./components/add-lead-modal";
import { Button } from "../../components/ui/button";
import { PlusCircle, Filter } from "lucide-react";
import { LeadGenContext } from "contexts/LeadGenContext";
import SalesFunnel from "./components/sales-funnel"; 

// Helper function to safely parse numeric values
const safeParseFloat = (value, defaultValue = 0) => {
  if (!value) return defaultValue;
  const parsed = parseFloat(value);
  return isNaN(parsed) ? defaultValue : parsed;
};

// Helper function to parse group names
const parseGroupNames = (groupStr) => {
  if (!groupStr) return [];
  return groupStr.split('|').map(name => name.trim()).filter(Boolean);
};

// Helper function to parse certifications
const parseCertifications = (certStr) => {
  if (!certStr) return [];
  
  // If already an array, return it directly
  if (Array.isArray(certStr)) {
    return certStr;
  }
  
  try {
    // Simple JSON parse for certification arrays
    return JSON.parse(certStr);
  } catch (error) {
    console.error("Error parsing certifications:", error);
    return [];
  }
};

const sourceMapping = {
  "SRS-CLIENT": "Churned Customer",
};

// All available statuses
const allStatuses = [
  "Prospective",
  "Acknowledging Lead",
  "First Attempt",
  "Second Attempt",
  "Third Attempt",
  "Engaged Prospect",
  "Needs Assessment",
  "Proposal or Quote",
  "Advancing the Sale",
  "Credit Application",
  "Converted",
  "Not Interested",
  "Churned",
  "Reactivated Customer",
  "Not a Good Lead",
  "Already a Customer",

  // Lost Lead - Red
  "Lost Lead",
];

const transformLeadData = (lead) => {
  // Check if it's a churned customer or regular lead
  const isChurnedCustomer = lead.lead_type === "churn" || lead.lead_type === "converted_srs" || lead.source === "SRS-CLIENT";
  const isTop3Lead = lead.lead_type === "top3";
  
  // Safely parse numeric values
  const rating = safeParseFloat(lead.rating, 0).toFixed(1);
  const reviews = safeParseFloat(lead.reviewCount || lead.reviews, 0);
  const score = safeParseFloat(lead.score, 0);
  
  // Format address based on lead type
  let address = lead.address || "";
  if (isChurnedCustomer && !address) {
    const addressParts = [];
    if (lead.streetaddr1) addressParts.push(lead.streetaddr1);
    if (lead.streetaddr2) addressParts.push(lead.streetaddr2);
    if (lead.city || lead.statecd || lead.zipcd) {
      const cityStateParts = [];
      if (lead.city) cityStateParts.push(lead.city);
      if (lead.statecd) cityStateParts.push(lead.statecd);
      if (lead.zipcd) cityStateParts.push(lead.zipcd);
      addressParts.push(cityStateParts.join(", "));
    }
    address = addressParts.join(", ");
  }
  
  // Use parseGroupNames for churned leads, otherwise use parseCertifications
  let certifications = isChurnedCustomer ? parseGroupNames(lead.group_names) : parseCertifications(lead.certifications);
  
  // Get the correct ID field
  const id = isChurnedCustomer ? (lead.custcd || lead.row_uuid) : (lead.row_uuid || lead.id);
  
  // Get the correct description/analysis
  const description = lead.description || "";
  const analysis = lead.analysis || lead.ai_insights || description;
  
   // Set default status first
  let status = lead.lead_status;
  
  // Override for special cases
  if (isChurnedCustomer && !lead.lead_status) {
    status = "Churned";
  } else if (isTop3Lead && !lead.lead_status) {
    status = "Prospective";
  }
  
  return {
    id: id,
    row_uuid: id,
    post_link: lead.post_link || "",
    certifications: certifications,
    name: lead.name || lead.custname || "",
    description: description,
    address: address,
    analysis: analysis,
    rating: rating,
    reviewCount: Math.round(reviews), // Round to nearest whole number
    phone: lead.phone || "",
    website: lead.website || lead.websitePreview || "",
    source: sourceMapping[lead.source] || lead.source || "",
    status: status,
    email: lead.email || "",
    score: score,
    notes: lead.notes || lead.status_notes || "",
    // Pass through the custcd for churned customers
    custcd: isChurnedCustomer ? (lead.custcd || id) : null,
    // Include feedback fields
    feedback: lead.feedback,
    feedback_notes: lead.feedback_notes || ""
  };
};

export default function Page() {
  const { leads } = useContext(LeadGenContext);
  const [selectedContractor, setSelectedContractor] = useState(null);
  const [selectedSources, setSelectedSources] = useState([]);
  const [selectedStatuses, setSelectedStatuses] = useState([]);
  const [isAddLeadModalOpen, setIsAddLeadModalOpen] = useState(false);

  const sources = ["GAF", "Thumbtack", "Churned Customer"];

  // Transform all leads with null checks
  const transformedLeads = (leads || []).map((lead) => transformLeadData(lead));

  // Filter leads by selected statuses
  const filteredLeads = selectedStatuses.length > 0
    ? transformedLeads.filter(lead => selectedStatuses.includes(lead.status))
    : transformedLeads;
  
  // Group by source for carousels if needed
  const leadsBySource = sources.reduce((acc, source) => {
    acc[source] = filteredLeads.filter(lead => lead.source === source);
    return acc;
  }, {});

  const toggleStatus = (status) => {
    setSelectedStatuses(prev => 
      prev.includes(status)
        ? prev.filter(s => s !== status)
        : [...prev, status]
    );
  };

  const handleAddLead = (leadData) => {
    console.log("New lead added:", leadData);
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div className="min-h-screen bg-white font-sans">
      <main className="container mx-auto py-6 px-4 sm:px-6 lg:px-8 space-y-8">
        <SalesFunnel 
          leads={transformedLeads} 
          selectedStatuses={selectedStatuses}
          onToggleStatus={toggleStatus}
        />

        <section className="space-y-6">
          <div className="grid gap-6 grid-cols-1 sm:grid-cols-2 lg:grid-cols-3">
            {filteredLeads.map((contractor) => (
              <ContractorCard
                key={contractor.id}
                {...contractor}
                onClick={() => setSelectedContractor(contractor.id)}
              />
            ))}
            
            {filteredLeads.length === 0 && (
              <div className="col-span-3 text-center py-12">
                <p className="text-gray-500">No leads match the selected filters.</p>
              </div>
            )}
          </div>
        </section>
      </main>

      <ContractorModal
        isOpen={!!selectedContractor}
        onClose={() => setSelectedContractor(null)}
        contractorId={selectedContractor}
        contractors={transformedLeads}
      />

      {/* <AddLeadModal
        isOpen={isAddLeadModalOpen}
        onClose={() => setIsAddLeadModalOpen(false)}
        onSubmit={handleAddLead}
      /> */}
    </div>
  );
}