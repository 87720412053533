import { AppContext } from "contexts/AppContext";
import React, { useState, useEffect, useContext } from "react";

const SalesFunnel = ({ leads, selectedStatuses = [], onToggleStatus }) => {
   const { isMobile } = useContext(AppContext);
   // Define status categories
   const assignedStatuses = ["Prospective", "Churned"];
   const actionedStatuses = [
     "Acknowledging Lead",
     "First Attempt",
     "Second Attempt",
     "Third Attempt",
   ];
   const qualifiedStatuses = [
     "Engaged Prospect",
     "Needs Assessment",
     "Proposal or Quote",
     "Advancing the Sale",
   ];
   const convertedStatuses = [
     "Credit Application", 
     "Converted", 
     "Reactivated Customer"
   ];
 
   // Grouped leads by their current status only (not cumulative)
   const pureAssignedLeads = leads.filter(lead => assignedStatuses.includes(lead.status));
   const pureActionedLeads = leads.filter(lead => actionedStatuses.includes(lead.status));
   const pureQualifiedLeads = leads.filter(lead => qualifiedStatuses.includes(lead.status));
   const pureConvertedLeads = leads.filter(lead => convertedStatuses.includes(lead.status));
 
   // Calculate percentages for width based on pure counts
   const totalLeads = leads.length;
   const assignedPercentage = totalLeads > 0 ? (pureAssignedLeads.length / totalLeads) * 100 : 0;
   const actionedPercentage = totalLeads > 0 ? (pureActionedLeads.length / totalLeads) * 100 : 0;
   const qualifiedPercentage = totalLeads > 0 ? (pureQualifiedLeads.length / totalLeads) * 100 : 0;
   const convertedPercentage = totalLeads > 0 ? (pureConvertedLeads.length / totalLeads) * 100 : 0;

  // Ensure minimum width for visibility
  const minWidth = 15;
  const adjustedAssignedWidth = Math.max(assignedPercentage, minWidth);
  const adjustedActionedWidth = Math.max(actionedPercentage, minWidth);
  const adjustedQualifiedWidth = Math.max(qualifiedPercentage, minWidth);
  const adjustedConvertedWidth = Math.max(convertedPercentage, minWidth);

  // State for showing/hiding statuses under each funnel level
  const [expandedSections, setExpandedSections] = useState({
    assigned: false,
    actioned: false,
    qualified: false,
    converted: false,
  });

  // Toggle function for expanding/collapsing sections
  const toggleSection = (section) => {
    setExpandedSections((prev) => ({
      ...prev,
      [section]: !prev[section],
    }));
  };

  // For conversion rate calculations, we use the total leads that go through each funnel stage
  const totalInitialLeads = totalLeads;
  const totalActionedLeads = pureActionedLeads.length + pureQualifiedLeads.length + pureConvertedLeads.length;
  const totalQualifiedLeads = pureQualifiedLeads.length + pureConvertedLeads.length;
  const totalConvertedLeads = pureConvertedLeads.length;
  
  // Calculate funnel metrics using flow-through rates
  const actionRate = totalInitialLeads > 0 
    ? Math.round((totalActionedLeads / totalInitialLeads) * 100) 
    : 0;
    
  const qualificationRate = totalActionedLeads > 0 
    ? Math.round((totalQualifiedLeads / totalActionedLeads) * 100) 
    : 0;
    
  const closingRate = totalQualifiedLeads > 0 
    ? Math.round((totalConvertedLeads / totalQualifiedLeads) * 100) 
    : 0;

  // Tooltip content for metric descriptions
  const tooltips = {
    actioned: "Percentage of total leads that reach the actioned stage or beyond.",
    qualification:
      "Percentage of actioned leads that advance to qualified status or beyond.",
    closing: "Percentage of qualified leads that convert to customers.",
  };

  // State for metric tooltips visibility
  const [activeTooltip, setActiveTooltip] = useState(null);

  return (
    <div className="mb-8 bg-white p-6 rounded-lg shadow-sm">
      <div className="grid grid-cols-3 gap-4 mb-6">
        <div className="p-4 bg-gray-50 rounded-lg text-center relative">
          <div className="text-sm text-gray-500 mb-1 flex items-center justify-center">
            <span>Actioned Rate</span>
            <div className="relative inline-block ml-1">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="h-4 w-4 text-gray-400 cursor-help"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
                onMouseEnter={() => setActiveTooltip("actioned")}
                onMouseLeave={() => setActiveTooltip(null)}
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth={2}
                  d="M13 16h-1v-4h-1m1-4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
                />
              </svg>
              {activeTooltip === "actioned" && (
                <div className="absolute z-10 w-48 p-2 text-xs text-left bg-white border border-gray-200 rounded shadow-lg -left-20 top-6">
                  {tooltips.actioned}
                </div>
              )}
            </div>
          </div>
          <div className="font-semibold text-xl mb-2">
            {actionRate}%
          </div>
        </div>
        <div className="p-4 bg-gray-50 rounded-lg text-center relative">
          <div className="text-sm text-gray-500 mb-1 flex items-center justify-center">
            <span>Qualification Rate</span>
            <div className="relative inline-block ml-1">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="h-4 w-4 text-gray-400 cursor-help"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
                onMouseEnter={() => setActiveTooltip("qualification")}
                onMouseLeave={() => setActiveTooltip(null)}
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth={2}
                  d="M13 16h-1v-4h-1m1-4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
                />
              </svg>
              {activeTooltip === "qualification" && (
                <div className="absolute z-10 w-48 p-2 text-xs text-left bg-white border border-gray-200 rounded shadow-lg -left-20 top-6">
                  {tooltips.qualification}
                </div>
              )}
            </div>
          </div>
          <div className="font-semibold text-xl mb-2">
            {qualificationRate}%
          </div>
        </div>
        <div className="p-4 bg-gray-50 rounded-lg text-center relative">
          <div className="text-sm text-gray-500 mb-1 flex items-center justify-center">
            <span>Closing Rate</span>
            <div className="relative inline-block ml-1">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="h-4 w-4 text-gray-400 cursor-help"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
                onMouseEnter={() => setActiveTooltip("closing")}
                onMouseLeave={() => setActiveTooltip(null)}
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth={2}
                  d="M13 16h-1v-4h-1m1-4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
                />
              </svg>
              {activeTooltip === "closing" && (
                <div className="absolute z-10 w-48 p-2 text-xs text-left bg-white border border-gray-200 rounded shadow-lg -left-20 top-6">
                  {tooltips.closing}
                </div>
              )}
            </div>
          </div>
          <div className="font-semibold text-xl mb-2">
            {closingRate}%
          </div>
        </div>
      </div>

      <div className="relative">
        {/* Funnel Visualization */}
        <div className="max-w-3xl mx-auto">
          {/* Assigned Stage */}
          <div className="relative mb-1 rounded-t-lg overflow-hidden bg-gradient-to-r from-purple-400 to-indigo-500 p-4">
            <div className="text-center text-white">
              <div className="font-bold text-lg">
                Assigned Leads
                <button
                  onClick={() => toggleSection("assigned")}
                  className="ml-2 focus:outline-none text-white absolute right-4"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="h-5 w-5"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth={2}
                      d="M9 5l7 7-7 7"
                    />
                  </svg>
                </button>
              </div>
              {expandedSections.assigned && (
                <div className="text-white text-sm my-2 flex justify-center">
                  <div className={`flex flex-col ${isMobile ? "items-center" : "items-start pl-8"} w-64`}>
                    <div className="flex items-center mb-1">
                      <input
                        type="checkbox"
                        className="w-4 h-4 text-indigo-600 rounded focus:ring-0 mr-2"
                        checked={selectedStatuses.includes(
                          "Prospective"
                        )}
                        onChange={() => onToggleStatus("Prospective")}
                      />
                      <span className="font-medium">
                        Prospective ({leads.filter(lead => lead.status === "Prospective").length})
                      </span>
                    </div>
                    <div className="flex items-center">
                      <input
                        type="checkbox"
                        className="w-4 h-4 text-indigo-600 rounded focus:ring-0 mr-2"
                        checked={selectedStatuses.includes("Churned")}
                        onChange={() => onToggleStatus("Churned")}
                      />
                      <span className="font-medium">Churned ({leads.filter(lead => lead.status === "Churned").length})</span>
                    </div>
                  </div>
                </div>
              )}

              <div className="font-semibold text-2xl">
                {pureAssignedLeads.length}
              </div>
            </div>
          </div>

          {/* Actioned Stage */}
          <div
            className="relative mb-1 overflow-hidden bg-gradient-to-r from-orange-400 to-red-500 mx-auto p-4"
            style={{
              width: `${Math.min(
                95,
                100 - (100 - adjustedActionedWidth) / 4
              )}%`,
            }}
          >
            <div className="text-center text-white">
              <div className="font-bold text-lg">
                Actioned Leads
                <button
                  onClick={() => toggleSection("actioned")}
                  className="ml-2 focus:outline-none text-white absolute right-4"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="h-5 w-5"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth={2}
                      d="M9 5l7 7-7 7"
                    />
                  </svg>
                </button>
              </div>
              {expandedSections.actioned && (
                <div className="text-white text-sm my-2 flex justify-center">
                  <div className={`flex flex-col ${isMobile ? "items-center" : "items-start pl-8"} w-64`}>
                    <div className="flex items-center mb-1">
                      <input
                        type="checkbox"
                        className="w-4 h-4 text-indigo-600 rounded focus:ring-0 mr-2"
                        checked={selectedStatuses.includes("Acknowledging Lead")}
                        onChange={() => onToggleStatus("Acknowledging Lead")}
                      />
                      <span className="font-medium">Acknowledging Lead ({leads.filter(lead => lead.status === "Acknowledging Lead").length})</span>
                    </div>
                    <div className="flex items-center mb-1">
                      <input
                        type="checkbox"
                        className="w-4 h-4 text-indigo-600 rounded focus:ring-0 mr-2"
                        checked={selectedStatuses.includes("First Attempt")}
                        onChange={() => onToggleStatus("First Attempt")}
                      />
                      <span className="font-medium">First Attempt ({leads.filter(lead => lead.status === "First Attempt").length})</span>
                    </div>
                    <div className="flex items-center mb-1">
                      <input
                        type="checkbox"
                        className="w-4 h-4 text-indigo-600 rounded focus:ring-0 mr-2"
                        checked={selectedStatuses.includes("Second Attempt")}
                        onChange={() => onToggleStatus("Second Attempt")}
                      />
                      <span className="font-medium">Second Attempt ({leads.filter(lead => lead.status === "Second Attempt").length})</span>
                    </div>
                    <div className="flex items-center">
                      <input
                        type="checkbox"
                        className="w-4 h-4 text-indigo-600 rounded focus:ring-0 mr-2"
                        checked={selectedStatuses.includes("Third Attempt")}
                        onChange={() => onToggleStatus("Third Attempt")}
                      />
                      <span className="font-medium">Third Attempt ({leads.filter(lead => lead.status === "Third Attempt").length})</span>
                    </div>
                  </div>
                </div>
              )}
              <div className="font-semibold text-2xl">
                {pureActionedLeads.length}
              </div>
            </div>
          </div>

          {/* Qualified Stage */}
          <div
            className="relative mb-1 overflow-hidden bg-gradient-to-r from-blue-400 to-cyan-500 mx-auto p-4"
            style={{
              width: `${Math.min(
                80,
                100 - (100 - adjustedQualifiedWidth) / 2
              )}%`,
            }}
          >
            <div className="text-center text-white">
              <div className="font-bold text-lg">
                Qualified Leads
                <button
                  onClick={() => toggleSection("qualified")}
                  className="ml-2 focus:outline-none text-white absolute right-4"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="h-5 w-5"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth={2}
                      d="M9 5l7 7-7 7"
                    />
                  </svg>
                </button>
              </div>
              {expandedSections.qualified && (
                <div className="text-white text-sm my-2 flex justify-center">
                  <div className={`flex flex-col ${isMobile ? "items-center" : "items-start pl-8"} w-64`}>
                    <div className="flex items-center mb-1">
                      <input
                        type="checkbox"
                        className="w-4 h-4 text-indigo-600 rounded focus:ring-0 mr-2"
                        checked={selectedStatuses.includes("Engaged Prospect")}
                        onChange={() => onToggleStatus("Engaged Prospect")}
                      />
                      <span className="font-medium">Engaged Prospect ({leads.filter(lead => lead.status === "Engaged Prospect").length})</span>
                    </div>
                    <div className="flex items-center mb-1">
                      <input
                        type="checkbox"
                        className="w-4 h-4 text-indigo-600 rounded focus:ring-0 mr-2"
                        checked={selectedStatuses.includes("Needs Assessment")}
                        onChange={() => onToggleStatus("Needs Assessment")}
                      />
                      <span className="font-medium">Needs Assessment ({leads.filter(lead => lead.status === "Needs Assessment").length})</span>
                    </div>
                    <div className="flex items-center mb-1">
                      <input
                        type="checkbox"
                        className="w-4 h-4 text-indigo-600 rounded focus:ring-0 mr-2"
                        checked={selectedStatuses.includes("Proposal or Quote")}
                        onChange={() => onToggleStatus("Proposal or Quote")}
                      />
                      <span className="font-medium">Proposal or Quote ({leads.filter(lead => lead.status === "Proposal or Quote").length})</span>
                    </div>
                    <div className="flex items-center">
                      <input
                        type="checkbox"
                        className="w-4 h-4 text-indigo-600 rounded focus:ring-0 mr-2"
                        checked={selectedStatuses.includes("Advancing the Sale")}
                        onChange={() => onToggleStatus("Advancing the Sale")}
                      />
                      <span className="font-medium">Advancing the Sale ({leads.filter(lead => lead.status === "Advancing the Sale").length})</span>
                    </div>
                  </div>
                </div>
              )}
              <div className="font-semibold text-2xl">
                {pureQualifiedLeads.length}
              </div>
            </div>
          </div>

          {/* Converted Stage */}
          <div
            className="relative mb-1 rounded-b-lg overflow-hidden bg-gradient-to-r from-green-500 to-emerald-600 mx-auto p-4"
            style={{
              width: `${Math.min(
                60,
                100 - (100 - adjustedConvertedWidth) / 1.5
              )}%`,
            }}
          >
            <div className="text-center text-white">
              <div className="font-bold text-lg">
                Converted Leads
                <button
                  onClick={() => toggleSection("converted")}
                  className="ml-2 focus:outline-none text-white absolute right-4"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="h-5 w-5"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth={2}
                      d="M9 5l7 7-7 7"
                    />
                  </svg>
                </button>
              </div>
              {expandedSections.converted && (
                <div className="text-white text-sm my-2 flex justify-center">
                  <div className={`flex flex-col ${isMobile ? "items-center" : "items-start pl-8"} w-64`}>
                    <div className="flex items-center mb-1">
                      <input
                        type="checkbox"
                        className="w-4 h-4 text-indigo-600 rounded focus:ring-0 mr-2"
                        checked={selectedStatuses.includes("Credit Application")}
                        onChange={() => onToggleStatus("Credit Application")}
                      />
                      <span className="font-medium">Credit Application ({leads.filter(lead => lead.status === "Credit Application").length})</span>
                    </div>
                    <div className="flex items-center mb-1">
                      <input
                        type="checkbox"
                        className="w-4 h-4 text-indigo-600 rounded focus:ring-0 mr-2"
                        checked={selectedStatuses.includes("Converted")}
                        onChange={() => onToggleStatus("Converted")}
                      />
                      <span className="font-medium">Converted ({leads.filter(lead => lead.status === "Converted").length})</span>
                    </div>
                    <div className="flex items-center">
                      <input
                        type="checkbox"
                        className="w-4 h-4 text-indigo-600 rounded focus:ring-0 mr-2"
                        checked={selectedStatuses.includes("Reactivated Customer")}
                        onChange={() => onToggleStatus("Reactivated Customer")}
                      />
                      <span className="font-medium">Reactivated Customer ({leads.filter(lead => lead.status === "Reactivated Customer").length})</span>
                    </div>
                  </div>
                </div>
              )}

              <div className="font-semibold text-2xl">
                {pureConvertedLeads.length}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SalesFunnel;