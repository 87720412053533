import PropTypes from "prop-types";
import React, { useContext, useEffect, useMemo, useState, useRef } from "react";
import { useOktaAuth } from "@okta/okta-react";
import { AppContext } from "contexts/AppContext";
import { getAccessToken, getLoggedInUser } from "utils/authUtils";

const AuthGuard = ({ children }) => {
  const { testing, setToken, setUser } = useContext(AppContext);
  const oktaAuthContext = useOktaAuth();
  const [tokenRenewalSetup, setTokenRenewalSetup] = useState(false);
  const initialLoginChecked = useRef(false);

  // All hooks must be called at the top level, before any conditional returns
  const authState = oktaAuthContext?.authState;
  const oktaAuth = oktaAuthContext?.oktaAuth;
  const isAuthenticated = useMemo(
    () => authState?.isAuthenticated,
    [authState]
  );
  const isLoading = useMemo(() => !authState, [authState]);

  // Handle initial login and page refresh if needed
  useEffect(() => {
    if (isAuthenticated && oktaAuth && !initialLoginChecked.current) {
      initialLoginChecked.current = true;

      const handleInitialLogin = async () => {
        // Check if this is a fresh login
        const isFirstLogin = !sessionStorage.getItem("auth_initialized");

        if (isFirstLogin) {
          try {
            // Get tokens and store in localStorage
            const tokens = await oktaAuth.tokenManager.getTokens();
            const storageObj = {};

            if (tokens.idToken) {
              storageObj.idToken = {
                idToken: tokens.idToken.idToken,
                claims: tokens.idToken.claims,
                expiresAt: tokens.idToken.expiresAt,
              };
            }

            if (tokens.accessToken) {
              storageObj.accessToken = {
                accessToken: tokens.accessToken.accessToken,
                claims: tokens.accessToken.claims,
                expiresAt: tokens.accessToken.expiresAt,
              };

              // Also update the app context if setToken is available
              if (setToken && typeof setToken === "function") {
                setToken(tokens.accessToken.accessToken);

                // Get user info and set in context
                if (setUser && typeof setUser === "function") {
                  try {
                    const userData = await getLoggedInUser(testing);
                    if (userData) {
                      setUser(userData);
                    }
                  } catch (userError) {
                    console.error("Error getting user data:", userError);
                  }
                }
              }
            }

            // Store in localStorage
            localStorage.setItem(
              "okta-token-storage",
              JSON.stringify(storageObj)
            );

            // Mark that we've initialized auth to prevent loops
            sessionStorage.setItem("auth_initialized", "true");

            // Reload the page to ensure a fresh start with tokens in place
            setTimeout(() => {
              window.location.reload();
            }, 1000);
          } catch (error) {
            console.error("Error during initial authentication setup:", error);
            sessionStorage.setItem("auth_initialized", "true"); // Prevent infinite loops
          }
        }
      };

      handleInitialLogin();
    }
  }, [isAuthenticated, oktaAuth, setToken, setUser, testing]);

  // Set up token auto-renewal
  useEffect(() => {
    if (isAuthenticated && oktaAuth && !tokenRenewalSetup) {
      const setupTokenRenewal = async () => {
        try {
          // Start the Okta Auth service (required for auto-renewal)
          await oktaAuth.start();

          // Configure token service options
          oktaAuth.options.services = {
            ...oktaAuth.options.services,
            autoRenew: true,
            autoRemove: true,
            syncStorage: true,
          };

          // Make oktaAuth globally available if needed elsewhere
          window.oktaAuth = oktaAuth;

          console.log("Okta token auto-renewal configured successfully");
          setTokenRenewalSetup(true);
        } catch (error) {
          console.error("Error setting up token renewal:", error);
        }
      };

      setupTokenRenewal();

      // Clean up function
      return () => {
        const stopService = async () => {
          try {
            await oktaAuth.stop();
          } catch (error) {
            console.error("Error stopping Okta Auth service:", error);
          }
        };

        stopService();
      };
    }
  }, [isAuthenticated, oktaAuth, tokenRenewalSetup]);

  // Set up event listeners for token events
  useEffect(() => {
    if (isAuthenticated && oktaAuth && tokenRenewalSetup) {
      // Handle token renewal events
      const handleTokenRenewed = async (key, newToken) => {
        console.log(`Token with key ${key} has been renewed`);

        try {
          // After renewal, update local storage with renewed tokens
          const tokens = await oktaAuth.tokenManager.getTokens();

          // Create the storage object in the format expected by your app
          const storageObj = {};

          if (tokens.idToken) {
            storageObj.idToken = {
              idToken: tokens.idToken.idToken,
              claims: tokens.idToken.claims,
              expiresAt: tokens.idToken.expiresAt,
            };
          }

          if (tokens.accessToken) {
            storageObj.accessToken = {
              accessToken: tokens.accessToken.accessToken,
              claims: tokens.accessToken.claims,
              expiresAt: tokens.accessToken.expiresAt,
            };

            // Also update context if available
            if (setToken && typeof setToken === "function") {
              setToken(tokens.accessToken.accessToken);
            }
          }

          localStorage.setItem(
            "okta-token-storage",
            JSON.stringify(storageObj)
          );

          // Refetch user data on token renewal
          if (setUser && typeof setUser === "function") {
            try {
              const userData = await getLoggedInUser(testing);
              if (userData) {
                setUser(userData);
              }
            } catch (userError) {
              console.error(
                "Error refreshing user data after token renewal:",
                userError
              );
            }
          }
        } catch (error) {
          console.error(
            "Error updating local storage after token renewal:",
            error
          );
        }
      };

      const handleTokenExpired = (key) => {
        console.log(`Token with key ${key} has expired`);
      };

      const handleTokenError = (error) => {
        console.error("Token renewal error:", error);

        // If token renewal fails with certain errors, you might want to force logout
        if (
          error.name === "OAuthError" &&
          (error.errorCode === "invalid_grant" ||
            error.errorCode === "login_required")
        ) {
          console.log("Session expired or invalid. Redirecting to login...");
          oktaAuth.signOut();
        }
      };

      // Register event listeners
      oktaAuth.tokenManager.on("renewed", handleTokenRenewed);
      oktaAuth.tokenManager.on("expired", handleTokenExpired);
      oktaAuth.tokenManager.on("error", handleTokenError);

      // Clean up function
      return () => {
        oktaAuth.tokenManager.off("renewed", handleTokenRenewed);
        oktaAuth.tokenManager.off("expired", handleTokenExpired);
        oktaAuth.tokenManager.off("error", handleTokenError);
      };
    }
  }, [
    isAuthenticated,
    oktaAuth,
    tokenRenewalSetup,
    setToken,
    setUser,
    testing,
  ]);

  // Initial token check
  useEffect(() => {
    if (isAuthenticated && oktaAuth) {
      const initToken = async () => {
        try {
          // Get current tokens
          const idToken = await oktaAuth.tokenManager.get("idToken");
          const accessToken = await oktaAuth.tokenManager.get("accessToken");

          // Sync tokens to localStorage for compatibility with existing code
          const storageObj = {};

          if (idToken) {
            storageObj.idToken = {
              idToken: idToken.idToken,
              claims: idToken.claims,
              expiresAt: idToken.expiresAt,
            };
          }

          if (accessToken) {
            storageObj.accessToken = {
              accessToken: accessToken.accessToken,
              claims: accessToken.claims,
              expiresAt: accessToken.expiresAt,
            };

            // Update token in context if available
            if (setToken && typeof setToken === "function") {
              setToken(accessToken.accessToken);
            }
          }

          localStorage.setItem(
            "okta-token-storage",
            JSON.stringify(storageObj)
          );

          // Update user in context if available
          if (
            setUser &&
            typeof setUser === "function" &&
            !sessionStorage.getItem("user_initialized")
          ) {
            try {
              const userData = await getLoggedInUser(testing);
              if (userData) {
                setUser(userData);
                sessionStorage.setItem("user_initialized", "true");
              }
            } catch (userError) {
              console.error(
                "Error getting user data during initialization:",
                userError
              );
            }
          }
        } catch (error) {
          console.error("Token initialization failed:", error);

          // If tokens can't be initialized, redirect to login
          if (error.name === "OAuthError") {
            oktaAuth.signOut();
          }
        }
      };

      initToken();
    }
  }, [isAuthenticated, oktaAuth, setToken, setUser, testing]);

  // Redirect to login if not authenticated
  useEffect(() => {
    if (oktaAuth && !isAuthenticated && !isLoading) {
      const currentUrl = window.location.href;
      if (!currentUrl.includes("/login/callback")) {
        oktaAuth.signInWithRedirect();
      }
    }
  }, [isAuthenticated, isLoading, oktaAuth]);

  // Now, after all hooks are called, we can use conditional returns
  if (testing) return children;

  // Check for existing access token
  const accessToken = getAccessToken(false);
  const mockToken =
    "eyJraWQiOiI1bXhYSTN1bEhjN2VTT3VDMS15TEM1ai1INVU1YmtJTVhNMm9SR05SeU1zIiwiYWxnIjoiUlMyNTYifQ.eyJzdWIiOiIwMHUxMHZndW5vbXZ4cVEzaDJwOCIsIm5hbWUiOiJOYWRpYSBIb3NzYWluIiwiZW1haWwiOiJOYWRpYS5Ib3NzYWluQHNyc2Rpc3RyaWJ1dGlvbi5jb20iLCJ2ZXIiOjEsImlzcyI6Imh0dHBzOi8vc3JzLm9rdGEuY29tL29hdXRoMi9hdXN2b2pndjl4dnpDU0RpWDJwNyIsImF1ZCI6IjBvYXY2eWFkMDdSa3NlNzVaMnA3IiwiaWF0IjoxNzQzNjA2NTY5LCJleHAiOjE3NDM2MTAxNjksImp0aSI6IklELmpYZENkNkxITFZQRlpueHdRUk1HX3lDRkx2dDNDVTNXcWUwOVJHMnE4YzAiLCJhbXIiOlsibWZhIiwib3RwIiwicHdkIl0sImlkcCI6IjAwbzE2emp3ZWNDRnU3cFVqMnA3Iiwibm9uY2UiOiJjeHhnSnF2eGs1b01rZUtCM2N0SWR1RU5kZjBNdGt3M1V5eXJ0N0tYVlR3a0ZrNXJ2TVM3eFFKbndzZ0dsNUdVIiwicHJlZmVycmVkX3VzZXJuYW1lIjoiTkgxMTAwMzZAbW1oZmdiLmNvbSIsImF1dGhfdGltZSI6MTc0MzYwNjU2NiwiYXRfaGFzaCI6ImJ4c1RlM0kyUWdYOFZ2UG5heFpMbncifQ.7CoX6gSiUt5HHM2dNlmsqLf1mZXQ2G4XwJS_jdBGuTN7Q6Z_7jAESc6uVROtbukXFYnhVprelXBg0IG1a0BM4w2aXXdFpp1UyuBkIQC3DE4xGAW0eLiaUIXPpw6cUFSkvjLv3VJ_5ZHn-hTJx4AwuVO7IJw0fRRjlTO7EzaaeH0xp3SYeZdA47A8u6ClRCD9Q5LKE4CBZoTgrEHh2V7wbwTFgxl7IVJflYhYKyOZwXxqStM8spy1Lh4yi_HlWBq7pVrR_tHEF8ydKm2Yxqsq0dI6ieRSMqqORYyvynd-jLk_T0kXv-yLPKnY2x2QCtoqqwoLjqejeurJ-KIN2FP-7Q";

  if (accessToken && accessToken !== mockToken) {
    return children;
  }

  if (!oktaAuthContext) {
    console.error(
      "AuthGuard: useOktaAuth() returned null. Make sure AuthGuard is wrapped in an OktaAuth provider."
    );
    return null;
  }

  if (isLoading || !isAuthenticated) return null;

  return children;
};

AuthGuard.propTypes = {
  children: PropTypes.node.isRequired,
};

export default React.memo(AuthGuard);
