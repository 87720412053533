import React, { createContext, useContext, useState, useEffect } from "react";
import axios from "axios";
import { AppContext } from "contexts/AppContext";

export const LeadGenContext = createContext();

export const LeadGenProvider = ({ children }) => {
  const { salesAgentCD, BASE_URL, oktaId, token } = useContext(AppContext);
  const [leads, setLeads] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);

  // Define statuses to exclude
  const excludedStatuses = ["Not a Good Lead", "Already a Customer", "Lost Lead"];

  // Helper function to filter out excluded statuses
  const filterExcludedStatuses = (leadsArray) => {
    return leadsArray.filter(lead => !excludedStatuses.includes(lead.lead_status));
  };

  const fetchLeads = async () => {
    if (!salesAgentCD) return;
    setIsLoading(true);
    setError(null);

    try {
      // Get top 3 leads
      const top3Response = await axios.get(
        `${BASE_URL}/top_3/${salesAgentCD}`,
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      );

      // Get churned leads
      const churnResponse = await axios.get(
        `${BASE_URL}/churn/${salesAgentCD}`,
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      );

      // Get converted leads
      const convertedResponse = await axios.get(
        `${BASE_URL}/converted_leads/${salesAgentCD}`,
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      );

      // Get converted SRS leads
      const convertedSrsResponse = await axios.get(
        `${BASE_URL}/converted_srs/${salesAgentCD}`,
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      );

      // Combine all lead types with a source identifier
      const combinedLeads = [
        ...filterExcludedStatuses(top3Response.data.contractors.map((lead) => ({
          ...lead,
          source: lead.source || "Contractor",
          lead_type: "top3",
        }))),
        ...filterExcludedStatuses(churnResponse.data.contractors.map((lead) => ({
          ...lead,
          source: "SRS-CLIENT",
          lead_type: "churn",
        }))),
        ...convertedResponse.data.contractors.map((lead) => ({
          ...lead,
          source: lead.source || "Contractor",
          lead_type: "converted",
        })),
        ...convertedSrsResponse.data.contractors.map((lead) => ({
          ...lead,
          source: "SRS-CLIENT",
          lead_type: "converted_srs",
        })),
      ];

      setLeads(combinedLeads);
      console.log("All leads fetched successfully", combinedLeads);
    } catch (err) {
      console.error("Problem with fetchLeads", err);
      setError("Failed to fetch leads. Please try again later.");
      setLeads([]);
    } finally {
      setIsLoading(false);
    }
  };

  const setLeadMapping = async (
    lead_id,
    status,
    notes = "",
    feedback = "null",
    feedbackNotes = ""
  ) => {
    try {
      // Basic input logging
      console.log(`Updating lead ${lead_id}: status=${status}, feedback=${feedback}`);
      
      // Create payload
      const payload = {
        salesagentcd: salesAgentCD,
        oktaid: oktaId,
        lead_id: lead_id,
        curr_date: new Date().toISOString().split("T")[0],
        status: status,
        status_notes: notes,
        feedback: feedback || "null",
        feedback_notes: feedbackNotes || ""
      };
  
      // API call
      const response = await axios.post(
        `${BASE_URL}/set_lead_mapping`,
        payload,
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
        }
      );
  
      console.log(`Lead mapping update successful: ${response.data}`);
      
      // Refresh leads
      await fetchLeads();
      
      return response.data;
    } catch (err) {
      console.error(`Failed to update lead ${lead_id}:`, err.message);
      throw err;
    }
  };

  useEffect(() => {
    if (salesAgentCD) {
      fetchLeads();
    }
  }, [salesAgentCD]);

  return (
    <LeadGenContext.Provider
      value={{
        leads,
        isLoading,
        error,
        fetchLeads,
        setLeadMapping,
      }}
    >
      {children}
    </LeadGenContext.Provider>
  );
};